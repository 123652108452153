// @ts-expect-error
import experimentFactory from '@wix/santa-main-r/lib/lib/common/experimentFactory'
import {getQueryUtils, PackagesUtil, getRjsConfig, RjsPkg} from './main-r'
import * as santa from '@wix/santa-ds-libs/src/santa'
import {checkConfig} from './checkConfig'
import {loadScript} from './utils'

export function genUnpkgUrl(packageName: string, version: string, path: string): string {
    return `https://static.parastorage.com/unpkg/${packageName}@${version}/${path}`
}

const loadRequireJs = async () => {
    if (!window.requirejs) {
        await loadScript(
            'https://static.parastorage.com/services/third-party/requirejs/2.1.15/require.min.js',
            'requirejs'
        )
    }
}

declare global {
    interface Window {
        define(name: string, cb: Function): void
        requirejs: {
            config(s: any): void
            (deps: string[], cb: Function): void
        }
        React: any
        ReactDOM: any
        pmrpc: any
        _: any
        queryUtils: any
    }
}

const santaUnpkg = (p: string) => genUnpkgUrl('@wix/wix-santa', santa.version, p)

const fixSantaPaths = (paths: Record<string, string>, packages: RjsPkg[]) => {
    packages.forEach((p: RjsPkg) => {
        //p.location = `https://localhost:8080/node_modules/@wix/wix-santa/${p.location}`
        p.location = santaUnpkg(p.location!)
    })
    const fixedPaths = checkConfig(paths)
    return fixedPaths
}

const defineExperimentInstance = () => {
    const experiments: string = window.queryUtils.getParameterByName('experiments')
    experiments.split(',').forEach(name => {
        window.rendererModel.runningExperiments[name] = 'new'
    })
    const experimentInst = experimentFactory.build(window)
    window.define('experiment', () => experimentInst)
}

const main = async () => {
    await loadRequireJs()
    const queryUtils = getQueryUtils(window)
    window.queryUtils = queryUtils
    const isDebug = !!queryUtils.getParameterByName('debug')
    defineExperimentInstance()
    const rjsConfig = getRjsConfig(santa.options.versions, window.serviceTopology, false, {} as any)
    rjsConfig.paths!.mainScript = `${window.dmBase}/tb-main/dist/tb-main-internal${isDebug ? '' : '.min'}`
    const packagesUtil = new PackagesUtil([], window.location.search)
    const {paths, packages, map, shim, bundles} = packagesUtil.buildConfig(rjsConfig)
    paths!.editorSkinsData = santaUnpkg('dist/editorSkinsData')
    const fixedPaths = fixSantaPaths(paths! as Record<string, string>, packages!)
    map!['*']['pm-rpc'] = 'pmrpc'
    window.requirejs.config({
        paths: fixedPaths,
        packages,
        map,
        shim,
        waitSeconds: 60,
        bundles
    })
    window.requirejs(
        ['react', 'react-dom', 'lodash', 'pmrpc'],
        (react: any, reactDom: any, lodash: any, pmrpc: any) => {
            window.React = react
            window.ReactDOM = reactDom
            window._ = lodash
            window.pmrpc = pmrpc
            window.requirejs(['mainScript'], () => {})
        }
    )
}

main()
